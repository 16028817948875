export default {
  props: {
  },
  computed: {
    gridColumns() {
      let cols = null;
      // 공정 - 구분 - 유해위험요인 - 현상태 - 위험도(전) - 조치 - 위험도(후) - 개선
      if (this.searchParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        cols = [
          {
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'diagramTitle',
            field: 'diagramTitle',
            description: 'diagramNo',
            // 도면
            label: 'LBL0001001',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            // 노드
            label: 'LBL0001004',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'deviationName',
            field: 'deviationName',
            // 이탈
            label: 'LBL0001005',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: 'LBL0001006',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            // 결과
            label: 'LBL0001007',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                type: 'custom',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'customCol',
            field: 'customCol',
            // 개선
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:130px',
            type: 'custom',
            sortable: false
          },
        ]
      } else if (this.searchParam.ramTechniqueCd === 'RT00000025') {
        // 4M
        cols = [
          {
            name: 'processName',
            field: 'processName',
            label: '제조공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '공정흐름(작업절차)',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'ram4mRiskHazardClassName',
            field: 'ram4mRiskHazardClassName',
            // 평가구분(4M)
            label: 'LBL0001112',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            // 유해위험요인
            label: 'LBL0001050',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'addingRiskManagementActivities',
            field: 'addingRiskManagementActivities',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                // 위험도
                label: 'LBL0001011',
                type: 'custom',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'customCol',
            field: 'customCol',
            // 개선
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
        ]
      } else if (this.searchParam.ramTechniqueCd === 'RT00000030') {
        // CHARM
        cols = [
          {
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },  
          {
            name: 'underProcessName',
            field: 'underProcessName',
            label: '단위공정',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'materialName',
            field: 'materialName',
            // 화학자재
            label: 'LBL0000356',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            // 현재안전보건조치
            label: 'LBL0001148',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'addingRiskManagementActivies',
            field: 'addingRiskManagementActivies',
            // 감소대책
            label: 'LBL0001149',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterExposureLevelFinal',
                field: 'afterExposureLevelFinal',
                // 노출수준
                label: 'LBL0001142',
                align: 'center',
                style: 'width:45px',
                sortable: false
              },
              {
                name: 'afterToxic',
                field: 'afterToxic',
                // 유해성
                label: 'LBL0001127',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'afterRiskEstimation',
                field: 'afterRiskEstimation',
                // 위험도
                label: 'LBL0001011',
                type: 'custom',
                align: 'center',
                style: 'width:70px',
                sortable: false,
              },
            ]
          },
          {
            name: 'customCol',
            field: 'customCol',
            // 개선
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
        ]
      }
      return cols;
    },
    gridMerge() {
      let merge = [];
      // 공정 - 구분 - 유해위험요인 - 현상태 - 위험도(전) - 조치 - 위험도(후) - 개선
      if (this.searchParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        merge = [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'processDiagram' }, // 도면
          { index: 2, colName: 'processDiagramNode' }, // 노드
          { index: 3, colName: 'processDiagramNodeGuide' },
        ]
      } else if (this.searchParam.ramTechniqueCd === 'RT00000025') {
        // 4M
        merge = [
          { index: 0, colName: 'processName' }, // 공정
          { index: 1, colName: 'processJob' }, // 작업
        ]
      } else if (this.searchParam.ramTechniqueCd === 'RT00000030') {
        // CHARM
        merge = [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'underProcessName' },
        ]
      } 
      return merge;
    },
    imprProps() {
      let props = {};
      // 공정 - 구분 - 유해위험요인 - 현상태 - 위험도(전) - 조치 - 위험도(후) - 개선
      if (this.searchParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        props = {
          tableKey: 'ramHazopAssessScenarioId', ibmTaskTypeCd: 'ITT0000001', ibmTaskUnderTypeCd: 'ITTU000001',
          requestContentsCols: ['deviationName', 'riskOccurrenceCause']
        }
      } else if (this.searchParam.ramTechniqueCd === 'RT00000025') {
        // 4M
        props = {
          tableKey: 'ram4mAssessScenarioId', ibmTaskTypeCd: 'ITT0000023', ibmTaskUnderTypeCd: 'ITTU000030',
          requestContentsCols: ['jobName', 'ram4mRiskHazardClassName', 'riskHazardName']
        }
      } else if (this.searchParam.ramTechniqueCd === 'RT00000030') {
        // CHARM
        props = {
          tableKey: 'ramCharmResultAssessScenarioId', ibmTaskTypeCd: 'ITT0000024', ibmTaskUnderTypeCd: 'ITTU000035',
          requestContentsCols: ['underProcessName', 'materialName']
        }
      } 
      return props;
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
}
